import React, { useEffect, useState } from "react";
import Layout from "containers/Layout";
import "./style/index.less";
import { Col, Row, Modal } from "antd";
import SettingsIcon from "components/Icons/SettingsIcon";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import { formatBusinessTime, translateHolidays } from "utils/common";
import { translateHolidays } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchByZipCode,
  fetchPostalCodeData,
  generateUUID,
  setPostalCodeData,
  fetchStoreById,
  deleteStore,
  setLoadedStore,
  isCreatedStore,
  // createStore,
} from "actions/store";
import { setError, setLoading, setSuccessMessage } from "actions/common";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormHeader from "components/FormHeader";
import { dataDianpingLinkage, dataPreview } from "./data";
import DataSidePreview from "components/DataSidePreview";
import StoreMasterForm from "components/Form/StoreMasterForm";
import { emailRegex } from "utils/constant";
// import { DIANPING_STOCKUNITS_MODES } from "../../constant";

const { confirm } = Modal;

export default function StoreMasterCreateUpdate() {
  const [businessHour, setBusinessHour] = useState([
    {
      startTimeHour: "",
      startTimeMinute: "",
      endTimeHour: "",
      endTimeMinute: "",
    },
  ]);
  // const defaultDayBusinessTimes = [
  //   {
  //     startTimeHour: "",
  //     startTimeMinute: "",
  //     endTimeHour: "",
  //     endTimeMinute: "",
  //   },
  // ];
  // const [staffEmailAddresses] = useState(["siang-hwa@e-cometrue.com"]);
  const { handleSubmit, watch, getValues, setValue, control, reset } = useForm({
    mode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      businessHours: businessHour,
      // dayBusinessTimes: defaultDayBusinessTimes,
      staffEmails: [
        {
          staffEmail: "",
        },
      ],
    },
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const dataForPreview = !id
    ? dataPreview
    : dataPreview.concat(dataDianpingLinkage(watch));
  // useState
  const [hasHolidays, setHasHolidays] = useState(false);
  const [checkedHolidays, setCheckedHolidays] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [useDianping, setUseDianping] = useState(false);
  const dianpingStockUnit = watch("stockUnit");

  // useSelector
  const postalCodeSource = useSelector(
    (state) => state.storeMasterCreateUpdateReducer.postalCodeData
  );
  const uuid = useSelector(
    (state) => state.storeMasterCreateUpdateReducer.UUID
  );
  const created = useSelector(
    (state) => state.storeMasterCreateUpdateReducer.isCreatedStore
  );
  const loadedStoreData = useSelector(
    (state) => state.storeMasterCreateUpdateReducer.loadedStoreData
  );
  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      setIsEdit(true);
      if (!loadedStoreData) {
        dispatch(fetchStoreById(id));
      }
    } else {
      dispatch(generateUUID());
    }
  }, [id]);

  useEffect(() => {
    if (id && loadedStoreData) {
      const netReservation = loadedStoreData.netReservation;
      if (!window.location.origin) {
        window.location.origin =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "");
      }
      const weeklyHolidays = [];
      loadedStoreData.weeklyHolidays.forEach((item) => {
        weeklyHolidays.push(item.id);
      });
      if (loadedStoreData.hasHolidays) weeklyHolidays.push(-2);
      // onChangeHolidays(weeklyHolidays);
      setCheckedHolidays(weeklyHolidays);
      const concatHolidays = [];
      setHasHolidays(false);
      weeklyHolidays.forEach((id) => {
        if (id === -2) {
          setHasHolidays(true);
          concatHolidays.push({ id: id, name: translateHolidays(id) });
        } else {
          concatHolidays.push({ id: id, name: translateHolidays(id) });
        }
      });
      const loadedBusinessHour = [];
      loadedStoreData.businessHours.forEach((item) => {
        loadedBusinessHour.push({
          startTimeHour: parseInt(item.openHours.split(":")[0]),
          startTimeMinute: item.openHours.split(":")[1],
          endTimeHour: parseInt(item.closeHours.split(":")[0]),
          endTimeMinute: item.closeHours.split(":")[1],
        });
      });
      setBusinessHour(
        loadedBusinessHour.length > 0 ? loadedBusinessHour : businessHour
      );
      const staffEmailArray = netReservation.staffEmail
        .split(",")
        .map((email) => {
          return { staffEmail: email };
        });
      setUseDianping(loadedStoreData.isDianping);
      const shopId = loadedStoreData.shopId
        ? loadedStoreData.shopId.toString()
        : "";
      // const timeSection = loadedStoreData.timeSection;
      // const businessTimes = timeSection
      //   ? timeSection.split(",").map((item) => {
      //       const splittedTime = item.trim().split(":");
      //       return {
      //         hour: parseInt(splittedTime[0]),
      //         minute: parseInt(splittedTime[1]),
      //       };
      //     })
      //   : [];
      // const loadedDayBusinessTimes =
      //   loadedStoreData.availableReservationTimes?.map((item) => {
      //     return {
      //       startTimeHour: parseInt(item.startTime.split(":")[0]),
      //       startTimeMinute: item.startTime.split(":")[1],
      //       endTimeHour: parseInt(item.endTime.split(":")[0]),
      //       endTimeMinute: item.endTime.split(":")[1],
      //     };
      //   });
      // const dayBusinessTimes =
      //   loadedDayBusinessTimes && loadedDayBusinessTimes.length > 0
      //     ? loadedDayBusinessTimes
      //     : defaultDayBusinessTimes;
      reset({
        name: loadedStoreData.name,
        displayName: loadedStoreData.displayName,
        postalCode: loadedStoreData.postalCode,
        address: loadedStoreData.address,
        phonenumber: loadedStoreData.phonenumber,
        nameTaberogu: loadedStoreData.nameTaberogu,
        nameGurunavi: loadedStoreData.nameGurunavi,
        nameHotopepper: loadedStoreData.nameHotopepper,
        businessHours:
          loadedBusinessHour.length > 0 ? loadedBusinessHour : businessHour,
        weeklyHolidays: concatHolidays,
        status: loadedStoreData.status.toString(),
        remindHour: loadedStoreData.remindHour,
        remindBeforeDate: loadedStoreData.remindBeforeDate,
        "netReservation.displayCrowdedPercent":
          netReservation.displayCrowdedPercent,
        "netReservation.netReservationNote": netReservation.netReservationNote,
        "netReservation.netReservationRequestNote":
          netReservation.netReservationRequestNote,
        "netReservation.netReservationThanksPageNote":
          netReservation.netReservationThanksPageNote,
        "netReservation.lineMeUrl": netReservation.lineMeUrl,
        "netReservation.reservedBeforeDay": netReservation.reservedBeforeDay,
        "netReservation.reservedBeforeDayEnd":
          netReservation.reservedBeforeDayEnd,
        "netReservation.reservedBeforeHourStart":
          netReservation.reservedBeforeHourStart,
        "netReservation.multipleMenuSelect":
          netReservation.multipleMenuSelect.toString(),
        "netReservation.reservationCondition":
          netReservation.reservationCondition,
        "netReservation.emailRequired": netReservation.emailRequired.toString(),
        "netReservation.menuRequired": netReservation.menuRequired.toString(),
        "netReservation.fullnameRequired":
          netReservation.fullnameRequired.toString(),
        "netReservation.dispCampaign": netReservation.dispCampaign.toString(),
        "netReservation.dispChildNum": netReservation.dispChildNum.toString(),
        staffEmails: staffEmailArray,
        // "netReservation.staffPhonenumber": netReservation.staffPhonenumber,
        displayNetReservation: loadedStoreData.displayNetReservation.toString(),
        formReservationStores:
          window.location.origin + "/rsv/?id=" + netReservation.uuid,
        isDianping: loadedStoreData.isDianping,
        shopId: shopId,
        // skuid: loadedStoreData.skuid,
        stockUnit: loadedStoreData.stockUnit
          ? `${loadedStoreData.stockUnit}`
          : "1",
        // businessTimes: businessTimes,
        // manageByPeople: loadedStoreData.manageByPeople
        //   ? `${loadedStoreData.manageByPeople}`
        //   : "1",
        // openTermStart: loadedStoreData.openTermStart
        //   ? dayjs(loadedStoreData.openTermStart)
        //   : null,
        // openTermEnd: loadedStoreData.openTermEnd
        //   ? dayjs(loadedStoreData.openTermEnd)
        //   : null,
        // dayBusinessTimes: dayBusinessTimes,
      });
      dispatch(setLoadedStore(null));
    }
  }, [loadedStoreData, reset]);

  useEffect(() => {
    if (created) {
      history.push("/settings/store-master");
      dispatch(setLoading(false));
      dispatch(isCreatedStore(false));
    }
  }, [created]);
  // Methods
  // PostalCode
  const updatePostalCode = (inputNumber) => {
    if (inputNumber.length <= 8) {
      setValue("postalCode", inputNumber);
      if (inputNumber.length > 3) dispatch(fetchPostalCodeData(inputNumber));
      else {
        dispatch(setPostalCodeData([]));
      }
    }
  };

  const handlePostalCodeSelection = (id) => {
    const detailsPostalCodeById = postalCodeSource.filter((postalCode) => {
      return postalCode.id === id;
    });
    updatePostalCode(detailsPostalCodeById[0].zipCode);
    const location =
      detailsPostalCodeById[0].prefecture.trim() +
      "" +
      detailsPostalCodeById[0].city.trim() +
      "" +
      detailsPostalCodeById[0].town.trim();
    setValue("address", location);
  };

  const onCancelHandler = () => {
    if (id) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        title: "確認",
        content: "編集した内容は破棄されます。よろしいですか？",
        okText: "はい",
        okType: "danger",
        cancelText: "いいえ",
        centered: true,
        onOk() {
          history.push("/settings/store-master");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      history.push("/settings/store-master");
    }
  };

  // Holidays
  const onChangeHolidays = (checkedHolidays) => {
    setCheckedHolidays(checkedHolidays);
    const concatHolidays = [];
    setHasHolidays(false);
    checkedHolidays.forEach((id) => {
      if (id === -2) {
        setHasHolidays(true);
        concatHolidays.push({ id: id, name: translateHolidays(id) });
      } else {
        concatHolidays.push({ id: id, name: translateHolidays(id) });
      }
    });
    setValue("weeklyHolidays", concatHolidays);
  };

  // StaffPhoneNumber

  const onCopyText = () => {
    dispatch(setSuccessMessage("クリップボードへコピーしました。"));
  };

  const onUpdate = (data) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "確認",
      content: "更新します。よろしいですか？",
      okText: "はい",
      okType: "danger",
      cancelText: "いいえ",
      centered: true,
      onOk() {
        onSubmit(data);
      },
      onCancel() {
        return false;
      },
    });
  };

  const handleValidateBusinessTime = () => {
    const hour = parseInt(getValues("stockHour").toString());
    const minute = parseInt(getValues("stockMinute").toString());
    const timeList = getValues("businessTimes");
    if (timeList && timeList.length > 0) {
      const alreadyAdded = timeList.find((item) => {
        return parseInt(item.hour) === hour && parseInt(item.minute) === minute;
      });
      if (alreadyAdded) {
        dispatch(setError("指定した時間は設定済みです。"));
        return false;
      }
    }
    return true;
  };

  const onSubmit = (data) => {
    data.netReservation.uuid = uuid;
    if (data.name === "" || data.name === undefined) {
      dispatch(setError("店舗名を入力してください。"));
      return false;
    }
    // businessHour
    const formattedBusinessHours = [];
    data.businessHours.forEach((businessHour) => {
      if (
        businessHour.startTimeHour &&
        businessHour.startTimeHour !== "" &&
        businessHour.startTimeMinute &&
        businessHour.startTimeMinute !== "" &&
        businessHour.endTimeHour &&
        businessHour.endTimeHour !== "" &&
        businessHour.endTimeMinute &&
        businessHour.endTimeMinute !== ""
      ) {
        formattedBusinessHours.push({
          openHours:
            businessHour.startTimeHour + ":" + businessHour.startTimeMinute,
          closeHours:
            businessHour.endTimeHour + ":" + businessHour.endTimeMinute,
        });
      }
      data.businessHours = formattedBusinessHours;
    });
    let isCorrectFormat = true;
    const staffEmails = data.staffEmails
      .filter((mail) => mail.staffEmail !== "")
      .map((mail) => {
        const e = mail.staffEmail;
        if (e.match(emailRegex) === null) {
          isCorrectFormat = false;
        }
        return mail.staffEmail;
      })
      .join(",");
    data.netReservation.staffEmail = staffEmails;
    if (!isCorrectFormat) {
      dispatch(
        setError("メールアドレスのフォーマットが正しく入力してください。")
      );
      return false;
    }
    if (data.netReservation.lineMeUrl !== "") {
      const urlRegex = /(https|http):\/\/.+/;
      if (data.netReservation.lineMeUrl.match(urlRegex) === null) {
        dispatch(setError("LINE友達登録用のリンクを正しく入力してください。"));
        return false;
      }
    }
    let error = false;
    if (data.businessHours.length > 0) {
      const cloneList = data.businessHours;
      cloneList.forEach((item) => {
        const startTime = dayjs(item.openHours, "HH:mm");
        const endTime = dayjs(item.closeHours, "HH:mm");
        if (startTime.isAfter(endTime)) {
          dispatch(setError("開始時間と終了時間が不正です。"));
          error = true;
          return false;
        }
      });
      cloneList.sort(function (arg1, arg2) {
        const startTime1 = dayjs(arg1.openHours, "HH:mm").toDate();
        const startTime2 = dayjs(arg2.openHours, "HH:mm").toDate();
        return startTime2 > startTime1 ? -1 : startTime2 < startTime1 ? 1 : 0;
      });
      for (let i = 0; i < cloneList.length - 1; i++) {
        const endTime1 = dayjs(cloneList[i].closeHours, "HH:mm");
        const startTime2 = dayjs(cloneList[i + 1].openHours, "HH:mm");
        if (endTime1.isAfter(startTime2) || endTime1.isSame(startTime2)) {
          dispatch(setError("開始時間と終了時間が不正です。"));
          error = true;
          return false;
        }
      }
    }
    if (error) return false;
    // validate storeid
    // In case of editing store information and the store has use_dianping = true
    // if (isEdit && useDianping) {
    if (process.env.REACT_APP_DIANPING_ENABLED === "true") {
      // if (data.shopId === undefined || data.shopId.trim() === "") {
      //   dispatch(setError("店舗IDを入力してください"));
      //   return false;
      // }
      // if (!data.openTermStart) {
      //   dispatch(setError("公開期間を指定してください"));
      //   return false;
      // }
      // if (!data.openTermEnd) {
      //   dispatch(setError("公開期間を指定してください"));
      //   return false;
      // }
      // compare openTermStart & openTermEnd
      // const openTermStart = dayjs(data.openTermStart);
      // const openTermEnd = dayjs(data.openTermEnd);
      // if (openTermEnd.isBefore(openTermStart)) {
      //   dispatch(setError("公開期間が不正です"));
      //   return false;
      // }
      // data.openTermStart = openTermStart.format("YYYY-MM-DD");
      // data.openTermEnd = openTermEnd.format("YYYY-MM-DD");
      // 在庫単位 = 日 (day)
      // if (data.stockUnit === DIANPING_STOCKUNITS_MODES.DAY) {
      // required skuid
      // if (!data.skuid || data.skuid.trim() === "") {
      //   dispatch(setError("skuidを入力してください。"));
      //   return false;
      // }
      // if (!data.dayBusinessTimes || data.dayBusinessTimes.length === 0) {
      //   dispatch(setError("予約可能時間を指定してください"));
      //   return false;
      // }
      // remove invalid dayBusinessTimes
      // const validDayBusinessTimes = data.dayBusinessTimes.filter((item) => {
      //   return !(
      //     isNaN(parseInt(item.startTimeHour)) ||
      //     isNaN(parseInt(item.startTimeMinute)) ||
      //     isNaN(parseInt(item.endTimeHour)) ||
      //     isNaN(parseInt(item.endTimeMinute))
      //   );
      // });
      // if (validDayBusinessTimes.length === 0) {
      //   dispatch(setError("予約可能時間を指定してください"));
      //   return false;
      // }
      // availableReservationTimes
      // const formattedAvailableReservationTimes = [];
      // validDayBusinessTimes.forEach((time) => {
      //   const startTime = formatBusinessTime(
      //     time.startTimeHour,
      //     time.startTimeMinute
      //   );
      //   const endTime = formatBusinessTime(
      //     time.endTimeHour,
      //     time.endTimeMinute
      //   );
      //   formattedAvailableReservationTimes.push({
      //     startTime: startTime,
      //     endTime: endTime,
      //   });
      // });
      // validate businessTimes in day mode
      // let error = false;
      // formattedAvailableReservationTimes.forEach((item) => {
      //   const startTime = dayjs(item.startTime, "HH:mm");
      //   const endTime = dayjs(item.endTime, "HH:mm");
      //   if (startTime.isAfter(endTime)) {
      //     dispatch(setError("開始時間と終了時間が不正です。"));
      //     error = true;
      //     return false;
      //   }
      // });
      // formattedAvailableReservationTimes.sort(function (arg1, arg2) {
      //   const startTime1 = dayjs(arg1.startTime, "HH:mm").toDate();
      //   const startTime2 = dayjs(arg2.startTime, "HH:mm").toDate();
      //   return startTime2 > startTime1 ? -1 : startTime2 < startTime1 ? 1 : 0;
      // });
      // for (
      //   let i = 0;
      //   i < formattedAvailableReservationTimes.length - 1;
      //   i++
      // ) {
      //   const endTime1 = dayjs(
      //     formattedAvailableReservationTimes[i].endTime,
      //     "HH:mm"
      //   );
      //   const startTime2 = dayjs(
      //     formattedAvailableReservationTimes[i + 1].startTime,
      //     "HH:mm"
      //   );
      //   if (endTime1.isAfter(startTime2) || endTime1.isSame(startTime2)) {
      //     dispatch(setError("開始時間と終了時間が不正です。"));
      //     error = true;
      //     return false;
      //   }
      // }
      // if (error) return false;
      // data.availableReservationTimes = formattedAvailableReservationTimes;
      // } else {
      //   // 在庫単位 = 時間 (time)
      //   if (
      //     data.businessTimes === undefined ||
      //     data.businessTimes.length === 0
      //   ) {
      //     dispatch(setError("在庫単位の時間を入力してください。"));
      //     return false;
      //   }
      //   data.timeSection = data.businessTimes
      //     .map((item) => {
      //       return formatBusinessTime(item.hour, item.minute);
      //     })
      //     .join(",");
      //   delete data.skuid;
      //   delete data.availableReservationTimes;
      // }
      data.stockUnit = parseInt(data.stockUnit);
      // data.manageByPeople = parseInt(data.manageByPeople);
      // delete data.businessTimes;
      // delete data.dayBusinessTimes;
      // }
    }
    data.weeklyHolidays = data.weeklyHolidays ? data.weeklyHolidays : [];
    data.hasHolidays = hasHolidays;
    dispatch(setLoading(true));
    if (id) data.id = id;
    dispatch(fetchByZipCode(data, id));
  };

  const deleteStoreById = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "確認",
      content:
        "削除したデータはもとに戻せません。店舗を削除してもよろしいですか？",
      okText: "はい",
      okType: "danger",
      cancelText: "いいえ",
      centered: true,
      onOk() {
        dispatch(setLoading(true));
        dispatch(setLoadedStore(null));
        dispatch(deleteStore(id));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Layout>
      <form
        className="form-container"
        onSubmit={!id ? handleSubmit(onSubmit) : handleSubmit(onUpdate)}
      >
        <FormHeader
          title={id ? "店舗の編集" : "店舗の新規登録"}
          icon={<SettingsIcon width={"28"} height={"28"} />}
        />
        <Row wrap={false}>
          <Col flex="auto">
            <StoreMasterForm
              control={control}
              handlePostalCodeSelection={handlePostalCodeSelection}
              fetchPostalCodeSuggestions={updatePostalCode}
              postalCodeSuggestions={postalCodeSource}
              watch={watch}
              onCopyText={onCopyText}
              isEdit={!!id}
              onChangeHolidays={onChangeHolidays}
              checkedHolidays={checkedHolidays}
              useDianping={useDianping}
              stockUnit={dianpingStockUnit}
              onValidateBusinessTime={handleValidateBusinessTime}
            />
          </Col>
          <DataSidePreview
            data={dataForPreview}
            control={control}
            title={"店舗マスタ"}
            onCancel={onCancelHandler}
            isEdit={isEdit}
            deleteHandler={deleteStoreById}
            submitButtonTitle={id ? "更新する" : "登録する"}
          />
        </Row>
      </form>
    </Layout>
  );
}
