import React from "react";
import PropTypes from "prop-types";
import GourmetSiteStoreMaterForm from "components/GourmetSiteStoreMaterForm";
import StoreInformation from "components/StoreInformation";
import SalesInformation from "components/SalesInformation";
import NetReservationPageSettings from "components/NetReservationPageSettings";
import DianpingLinkageStoreMaterForm from "../DianpingLinkageStoreMasterForm";
function StoreMasterForm({
  control,
  handlePostalCodeSelection,
  fetchPostalCodeSuggestions,
  postalCodeSuggestions,
  watch,
  isEdit,
  onCopyText,
  onChangeHolidays,
  checkedHolidays,
  useDianping,
  stockUnit,
  onValidateBusinessTime,
}) {
  return (
    <div className="form-wrapper">
      <StoreInformation
        control={control}
        handlePostalCodeSelection={handlePostalCodeSelection}
        fetchPostalCodeSuggestions={fetchPostalCodeSuggestions}
        postalCodeSuggestions={postalCodeSuggestions}
      />
      <SalesInformation
        control={control}
        onChangeHolidays={onChangeHolidays}
        checkedHolidays={checkedHolidays}
      />
      {/* <RemindMessageSettings control={control} /> */}
      <NetReservationPageSettings
        control={control}
        watch={watch}
        onCopyText={onCopyText}
        isEdit={isEdit}
      />
      <GourmetSiteStoreMaterForm control={control} />
      {process.env.REACT_APP_DIANPING_ENABLED === "true" && (
        <DianpingLinkageStoreMaterForm
          control={control}
          stockUnit={stockUnit}
          onValidateBusinessTime={onValidateBusinessTime}
        />
      )}
    </div>
  );
}

StoreMasterForm.propTypes = {
  control: PropTypes.any,
  handlePostalCodeSelection: PropTypes.func,
  fetchPostalCodeSuggestions: PropTypes.func,
  postalCodeSuggestions: PropTypes.array,
  watch: PropTypes.any,
  isEdit: PropTypes.bool,
  onCopyText: PropTypes.func,
  onChangeHolidays: PropTypes.func,
  checkedHolidays: PropTypes.array,
  useDianping: PropTypes.bool,
  stockUnit: PropTypes.string,
  onValidateBusinessTime: PropTypes.func,
};

export default StoreMasterForm;
